import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { map, catchError, first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ControlMessage } from '../interface/control-message';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../component/dialog/confirmation-dialog/confirmation-dialog.component';
import { DataService } from './data.service';
import { ControlMessageType } from '../interface/enum-controlMessages-type';

@Injectable({
  providedIn: 'root'
})
export class ControlMessageService {

  private _isFromMatrix = true;
  private apiUrl = `${environment.mkgoURL}/api/v1/control-messages`;

  // Subject para manter as mensagens de controle
  private messagesSubject: BehaviorSubject<ControlMessage[]> = new BehaviorSubject<ControlMessage[]>([]);
  // Observable para permitir que outros componentes se inscrevam para receber atualizações das mensagens
  messages$: Observable<ControlMessage[]> = this.messagesSubject.asObservable();

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private dataService: DataService
  ) {
    // Carrega as mensagens iniciais
    this.loadMessages();
  }

  // Método para carregar as mensagens
  async loadMessages(): Promise<void> {
    this.dataService.httpOptions(true).subscribe(header => {
      this.http.get<{ controlMessages: ControlMessage[] }>(this.apiUrl, header).pipe(
        map(response => response.controlMessages),
        map(messages => messages.map(message => {
          // Converte qualquer string Unicode de volta para emoji
          if (message?.message) {
            message.message = this.fromUnicode(message.message);
          }
          return message;
        })),
        catchError(error => {
          console.error('Erro ao carregar mensagens de controle', error);
          return [];
        })
      ).subscribe(
        (messages: ControlMessage[]) => {
          if (messages.length === 0) {
            this.loadExampleMessages(); // Carrega os dados de exemplo se não houver mensagens válidas
          } else {
            this.messagesSubject.next(messages);
          }
        },
        error => {
          console.error('Erro ao carregar mensagens de controle', error);
          this.loadExampleMessages(); // Carrega os dados de exemplo em caso de erro na requisição
        }
      );
    });
  }


  // Método para carregar as mensagens de exemplo
  private loadExampleMessages(): void {
    this.messagesSubject.next([]);
  }

  // Método para atualizar uma mensagem
  async updateMessage(updatedMessage: ControlMessage): Promise<void> {
    try {
      const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
      const response = await firstValueFrom(this.http.put(`${this.apiUrl}/${updatedMessage._id}`, updatedMessage, header));

      const messages = this.messagesSubject.getValue();
      const index = messages.findIndex(msg => msg._id === updatedMessage._id);
      if (index !== -1) {
        messages[index] = updatedMessage;
        this.messagesSubject.next([...messages]);
      }

      return;
    } catch (error) {
      console.error('Erro ao atualizar a mensagem', error);
    }
  }


  async deleteMessage(messageId: string) {
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    return this.http.delete(`${this.apiUrl}/${messageId}`, header).pipe(first()).toPromise();
  }

  private fromUnicode(value: string): string {
    return value.replace(/\\u[\dA-F]{4}/gi, (match) => {
      return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
    });
  }


  private toAPI(controlMessage: ControlMessage): ControlMessage {
    delete controlMessage._id;

    if (controlMessage.time === undefined || controlMessage.time === null) {
      delete controlMessage.time;
    }
    // Converte emojis para string Unicode antes de enviar para o banco de dados
    if (controlMessage.message) {
      controlMessage.message = controlMessage.message.split('')
        .map(char => {
          const codePoint = char.codePointAt(0);
          // Check if the character is an emoji (partial range as an example)
          if ((codePoint >= 0x1F600 && codePoint <= 0x1F64F) || // Emoticons
            (codePoint >= 0x1F300 && codePoint <= 0x1F5FF) || // Miscellaneous Symbols and Pictographs
            (codePoint >= 0x1F680 && codePoint <= 0x1F6FF) || // Transport and Map Symbols
            (codePoint >= 0x2600 && codePoint <= 0x26FF)) {   // Miscellaneous Symbols
            return '\\u' + codePoint.toString(16).padStart(4, '0');
          }
          return char; // Leave other characters as-is
        })
        .join('');
    }

    return controlMessage;
  }


  async saveMessage(message: ControlMessage): Promise<void> {
    const messages = this.messagesSubject.getValue();
    const isDueTitleMessage = message.type === ControlMessageType.DueTitleMessage;

    // Para mensagens de vencimento (DueTitleMessage), verifica apenas se a própria mensagem tem _id
    // Para outros tipos, segue a lógica original de buscar no array
    let existingMessage: ControlMessage | undefined;

    if (isDueTitleMessage) {
      // Para DueTitleMessage, verifica diretamente se a mensagem tem _id
      existingMessage = message._id ? message : undefined;
    } else {
      // Para outros tipos, mantém a lógica de buscar no array
      existingMessage = messages.find(msg => msg.type === message.type);
    }

    if (existingMessage?._id) {
      message._id = existingMessage._id;
      await this.updateMessage(message);
    } else {
      const messageToSave = this.toAPI(message);
      const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
      this.http.post(this.apiUrl, messageToSave, header).subscribe(
        response => {
          // Atualiza a lista de mensagens localmente após a operação de salvar
          if (message?.message) {
            message.message = this.fromUnicode(message.message);
          }
          messages.push(message);
          this.messagesSubject.next([...messages]);
        },
        error => {
          console.error('Erro ao salvar a mensagem', error);
          this.openConfirmationDialog();
        }
      );
    }
  }

  // Método para abrir um dialog de confirmação
  private openConfirmationDialog(): void {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        text: 'FALHA_CRIAR_MENSAGEM',
        uniqueAction: true
      }
    });
  }

  // Método para buscar uma mensagem pelo tipo
  getMessageByType(type: ControlMessageType): ControlMessage | undefined {
    const messages = this.messagesSubject.getValue();
    return messages.find(msg => msg.type === type);
  }

  // Método para obter uma mensagem pelo typeId
  getMessageByTypeId(typeId: number): ControlMessage | undefined {
    const messages = this.messagesSubject.getValue();
    return messages.find(message => message.type === typeId);
  }
}
