import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RoType, checklistAssig } from '../../../interface/ro-type';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RoTypeService } from '../../../service/ro-type.service';
import { LayoutService } from '../../../service/layout.service';
import { SnackbarService } from '../../../service/snackbar.service';
import { RoTypeUtilities } from '../../../class/ro-type-utilities';
import { DataService } from '../../../service/data.service';
import { combineLatest, filter, map, switchMap } from 'rxjs';
import { Utilities } from '../../../class/utilities';
import { Operation } from '../../../interface/operation';
import { UnsubscribeAll } from '../../../class/unsubscribe-all';

@Component({
  selector: 'app-ro-type-edit',
  templateUrl: './ro-type-edit.component.html',
  styleUrl: './ro-type-edit.component.scss'
})
export class RoTypeEditComponent extends UnsubscribeAll implements OnInit, OnDestroy {
  myCompanies = [];

  form: FormGroup = this.formBuilder.group({
    id: [''],
    type: ['', [Validators.required]],
    observation: [''],
    nfseCnpj: ['', [Utilities.validateCNPJ()]],
    nfeCnpj: ['', [Utilities.validateCNPJ()]],
    operation: ['', [Validators.required]],
    checklistAssig: [false, [Validators.required]]
  }, {
    validator: Utilities.distinctControls('nfseCnpj', 'nfeCnpj')
  });

  private roType: RoType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { roType: RoType, operations: Operation[] },
    public dialogRef: MatDialogRef<RoTypeEditComponent>,
    private roTypeService: RoTypeService,
    private layout: LayoutService,
    private snackbar: SnackbarService,
    private formBuilder: FormBuilder,
    private _dataservice: DataService
  ) {
    super();
    if (data && data.roType) {
      this.roType = {
        ...data.roType,
        nfeCnpj: data.roType.nfeCnpj?.trim() ? data.roType.nfeCnpj : "",
        observation: data.roType.observation?.trim() ? data.roType.observation : "",
        nfseCnpj: data.roType.nfseCnpj?.trim() ? data.roType.nfseCnpj : "",
      };
    }
  }

  ngOnInit() {
    if (this.roType) {
      this.form.patchValue(this.roType);
      this.form.get('checklistAssig').setValue(this.roType.checklistAssig === 1 ? false : true);
    }
    this.dialogRef.backdropClick().subscribe(() => this.closeIfUnchanged());

    // on input nfse CNPJ, define default nfe CNPJ
    this.subs.push(
      this.form.get('nfseCnpj').valueChanges.subscribe(value => {
        if(value){
          this.form.get('nfeCnpj').setValue(this._dataservice.company.cnpj, { emitEvent: false });
        }
      }))

    // on input nfe CNPJ, define default nfse CNPJ
    this.subs.push(
      this.form.get('nfeCnpj').valueChanges.subscribe(value => {
        if(value){
          this.form.get('nfseCnpj').setValue(this._dataservice.company.cnpj, { emitEvent: false });
        }
      })
    )

    this.subs.push(this._dataservice.user$.pipe(
      filter(u => !!u),
      switchMap((user) => combineLatest([
        user.ownCompanies$,
        user.linkedCompanies$
      ])),
      map(([own, linked]) => own.concat(linked))
    ).subscribe((userCompanies) => this.myCompanies = userCompanies));
  }

  override ngOnDestroy(): void {
    this.unsubscribeAll()
  }

  async onSubmit() {
    this.layout.loader = true;
    let roType: RoType = this.form.getRawValue();
    try {
      if (roType.id) {
        roType.checklistAssig = this.form.get('checklistAssig').value ? 2 : 1;
        await this.roTypeService.update(roType)
        this.snackbar.success('RO_TYPE.UPDATED', { code: roType.code });
      } else {
        roType.checklistAssig = this.form.get('checklistAssig').value ? 2 : 1;
        const id = await this.roTypeService.add(roType);
        roType["id"] = id;
        this.snackbar.success('RO_TYPE.ADDED', { code: roType.code });
      }
      this.dialogRef.close(roType);
    } catch (error) {
      this.layout.loader = false;
      this.snackbar.error(roType.id ? 'RO_TYPE.NOT_UPDATED' : 'RO_TYPE.NOT_ADDED', { code: roType.code }, error);
    } finally {
      this.layout.loader = false;
    }
  }

  @HostListener('window:keyup.esc')
  onKeyUp() {
    this.closeIfUnchanged();
  }

  private closeIfUnchanged() {
    if (!RoTypeUtilities.changed(this.roType, this.form.value)) {
      this.dialogRef.close();
    }
  }
}

